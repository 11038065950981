@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.max-w-2xl { max-width: 42rem; }
.mx-auto { margin-left: auto; margin-right: auto; }
.p-4 { padding: 1rem; }
.mb-4 { margin-bottom: 1rem; }
.mt-6 { margin-top: 1.5rem; }
.w-full { width: 100%; }
.flex { display: flex; }
.justify-between { justify-content: space-between; }
.text-2xl { font-size: 1.5rem; line-height: 2rem; }
.font-bold { font-weight: 700; }
.rounded { border-radius: 0.25rem; }
.border { border-width: 1px; }
.bg-gray-300 { background-color: #d1d5db; }
.bg-blue-500 { background-color: #3b82f6; }
.text-gray-800 { color: #1f2937; }
.text-white { color: #ffffff; }
.hover\:bg-gray-400:hover { background-color: #9ca3af; }
.hover\:bg-blue-600:hover { background-color: #2563eb; }
.px-4 { padding-left: 1rem; padding-right: 1rem; }
.py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; } */
